<template>
  <v-app>
    <v-main>
      <v-container fill-height fluid>
        <v-row class="justify-center align-center">
          <v-col cols="auto" class="d-flex justify-center">
            <v-card elevation="7" outlined round max-width="500px" width="350px">
              <v-toolbar color="secondary">
                <v-toolbar-title>Login</v-toolbar-title>
              </v-toolbar>
              <v-card-text class="mt-4">
                <v-text-field :disabled="loading" v-model="user" outlined label="User or Email"></v-text-field>
                <v-text-field :disabled="loading" v-model="pw" outlined label="Password"></v-text-field>
              </v-card-text>
              <v-card-actions class="mb-4">
                <v-spacer></v-spacer>
                <v-btn :disabled="loading" :loading="loading" @click="login" color="primary">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'


export default {
  name: 'App',

  components: {
  },

  data: () => ({
    user: null,
    pw: null,
    loading: false,
  }),

  methods: {
    login(){
      this.loading = true
      const user = this.user
      const pw = this.pw
      if (!pw || !user) {
        this.loading = false
        console.log(user, pw, 'false')
        return
      }
      const params = new URLSearchParams()
      params.append('username', user)
      params.append('password', pw)
      params.append('grant_type', 'password')
      params.append('client_id', 'caddy')

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      const url = 'https://austinch.com/auth/realms/austinch/protocol/openid-connect/token'

      axios.post(url, params, config).then((resp) => {
        console.log(resp)
      }).catch((err) => {
        console.error(err)
      })

    },
    reset(){
      this.loading = false
      this.user = ''
      this.pw = ''
    }
  },

  created(){
    this.$vuetify.theme.dark = true
  }
};
</script>
